import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "@components/layout";
import SEO from "@components/seo";
import Modal from "react-bootstrap/Modal";
import { LazyLoadComponent } from "react-lazy-load-image-component";

//faq accordion
import FaqAccordion from "@components/faqAccordion/faqAccordion";

//background decorations
import Dots from "@components/scrollMagic/dots";
import DotsCircle from "@components/scrollMagic/dotsCircle";
import DotsCircleMultiple from "@components/scrollMagic/dotsCircleMultiple";

//hero
import IndexHero from "@components/indexHero/indexHeroSection";

//animation effects
import { Fade, Zoom, Bounce } from "react-reveal";

//website showcase
const caseStudyShowcase = [
  {
    name: "Zenith Chiropractic",
    img: require("@images/showcase/zenithchiroco.jpg"),
    caseStudyLink: "/",
  },
  {
    name: "Cryolab",
    img: require("@images/showcase/cryolab.jpg"),
    caseStudyLink: "/",
  },
  {
    name: "Sandy Scott",
    img: require("@images/showcase/sandyscottllc.jpg"),
    caseStudyLink: "/",
  },
  {
    name: "McGregor Wealth",
    img: require("@images/showcase/mcgregorwealth.jpg"),
    caseStudyLink: "/",
  },
  {
    name: "Management Insight",
    img: require("@images/showcase/managementinsight.jpg"),
    caseStudyLink: "/",
  },
  {
    name: "Pikes Peak Security",
    img: require("@images/showcase/pikespeaksecurity.jpg"),
    caseStudyLink: "/",
  },
];

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title;
    const siteDescription = this.props.data.site.siteMetadata.description;
    const posts = this.props.data.allSanityPost.edges;
    console.log(posts);
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" description={siteDescription} />

        <IndexHero />

        <div className="video-section" id="thesis">
          <div className="container">
            <div className="learn-about-heading">
              <span className="d-block text-white learn-about-our">
                <Bounce right cascade>
                  Learn about our
                </Bounce>
              </span>
              <span className="d-block text-orange thesis">
                <Fade delay={500}>thesis.</Fade>
              </span>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <Fade up delay={1000}>
                  <div className="hero-video-thumb shadow-dark-lg rounded-lg">
                    <div className="play-btn-wrapper">
                      <button
                        className="btn play-btn"
                        onClick={this.handleShow}
                      >
                        <i className="fa fa-play"></i>
                      </button>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>

            <Modal
              dialogClassName="video-modal"
              show={this.state.show}
              onHide={this.handleClose}
              centered
            >
              <Modal.Body>
                <button
                  className="close-video-modal"
                  onClick={this.handleClose}
                >
                  <i className="fa fa-close" />
                </button>
                <div id="vsl" className="embed-container shadow-lg-dark">
                  <iframe
                    src="https://player.vimeo.com/video/308469803"
                    title="VSL"
                    autoplay
                    frameborder="0"
                    webkitAllowFullScreen
                    mozallowfullscreen
                    allowFullScreen
                  ></iframe>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <DotsCircle animationTrigger=".video-section" />
        </div>

        <div className="about-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <Zoom>
                  <>
                    <h6 className="text-uppercase text-orange pb-2">
                      Why WebriQ?
                    </h6>
                    <h2 className="pb-4 text-white">
                      We are the ONLY provider of Sales Funnel Websites as a
                      service.
                    </h2>
                  </>
                </Zoom>
                <Fade up delay={500}>
                  <p className="pb-4">
                    Fact is, amazing websites aren't made with wordpress themes
                    or diy tools. They're not even made with great creative
                    agencies. They're made from teams that understand what it
                    takes to drive deals online, and that can take the reigns
                    for the most important sales tool in every business -- their
                    website.
                  </p>
                  <p className="pb-4">
                    We want to be the go-to solution for SaaS companies and
                    Online Businesses who want to build a website that actually
                    converts visitors to leads and customers.
                  </p>
                  <>
                    <Link
                      to="/"
                      className="btn btn-primary btn-white-transparent m-2 shadow-dark"
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/"
                      className="btn btn-primary btn-orange m-2 shadow-dark"
                    >
                      Get a Price
                    </Link>
                  </>
                </Fade>
              </div>
            </div>

            <Fade up>
              <div className="website-showcase">
                <div className="row justify-content-center">
                  {caseStudyShowcase.map((website) => (
                    <div className="col-md-4 mb-5">
                      <div className="snapshot-wrapper">
                        <Link to={website.caseStudyLink}>
                          <div
                            className="snapshot"
                            style={{ backgroundImage: `url(${website.img})` }}
                          ></div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Fade>
          </div>
          <div className="about-bg">
            <div className="w-logo-bg"></div>
          </div>
          <Dots animationTrigger=".about-section" />
          <svg
            className="wave"
            width="100%"
            height="250"
            viewBox="0 0 1440 250"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 200.231L60 174.769C120 149.884 240 100.116 360 104.167C480 108.218 600 166.667 720 166.667C840 166.667 960 108.218 1080 70.6019C1200 33.5648 1320 16.7824 1380 8.10185L1440 0V250H1380C1320 250 1200 250 1080 250C960 250 840 250 720 250C600 250 480 250 360 250C240 250 120 250 60 250H0V200.231Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="py-100 bg-white unlimiteds-section">
          <div className="container">
            <div className="row unlimiteds">
              <div className="col-md-4 mb-5">
                <Bounce>
                  <>
                    <img
                      alt="/"
                      src={require("@images/icons/unlimited-pages.svg")}
                      width="100px"
                    />
                    <h5 className="pt-4 pages text-uppercase">
                      Unlimited Pages
                    </h5>
                    <p>For your marketing needs.</p>
                  </>
                </Bounce>
              </div>

              <div className="col-md-4 mb-5">
                <Bounce delay={500}>
                  <>
                    <img
                      alt="/"
                      src={require("@images/icons/unlimited-funnels.svg")}
                      width="100px"
                    />
                    <h5 className="pt-4 funnels text-uppercase">
                      Unlimited Funnels
                    </h5>
                    <p>Sales funnels that actually convert.</p>
                  </>
                </Bounce>
              </div>
              <div className="col-md-4 mb-5">
                <Bounce delay={1000}>
                  <>
                    <img
                      alt="/"
                      src={require("@images/icons/unlimited-updates.svg")}
                      width="100px"
                    />
                    <h5 className="pt-4 updates text-uppercase">
                      Unlimited Updates
                    </h5>
                    <p>No-limits web development.</p>
                  </>
                </Bounce>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="container">
            <Zoom cascade>
              <h3 className="unlimited-possibilities text-center font-weight-bold text-primary text-uppercase display-4 m-0">
                Unlimited Possibilities
              </h3>
            </Zoom>
          </div>
        </div>

        <div id="saas" className="services-section bg-white">
          <div className="container">
            <div className="row align-items-center service-row-item">
              <div className="col-md-6">
                <Fade left>
                  <div className="service-img-wrapper saas">
                    <Bounce delay={800}>
                      <img
                        alt="/"
                        className="service-icon saas"
                        src={require("@images/icons/sales-website-as-a-service.svg")}
                        width="125px"
                      />
                    </Bounce>
                    <div className="service-img saas rounded"></div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6">
                <h2 className="text-center text-md-left pt-5 pb-4">
                  Sales Website as a Service
                </h2>
                <p>
                  We will design, develop, and deploy amazing sales websites
                  that actually convert deals into customers. Great sites are
                  always changing, which is why we stay on as a consistent
                  partner for our clients -- offering unlimited pages, and
                  unlimited requests for their marketing needs.
                </p>
              </div>
            </div>
            <div className="row align-items-center service-row-item">
              <div className="col-md-6 order-2 order-md-1">
                <h2 className="text-center text-md-left pt-5 pb-4">
                  E-Commerce Add Ons
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <Fade right>
                  <div className="service-img-wrapper ecomm">
                    <Bounce delay={800}>
                      <img
                        alt="/"
                        className="service-icon ecomm"
                        src={require("@images/icons/ecomm.svg")}
                        width="125px"
                      />
                    </Bounce>
                    <div className="service-img ecomm rounded"></div>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="row align-items-center service-row-item">
              <div className="col-md-6">
                <Fade left>
                  <div className="service-img-wrapper cms">
                    <Bounce delay={800}>
                      <img
                        alt="/"
                        className="service-icon cms"
                        src={require("@images/icons/cms-addon.svg")}
                        width="125px"
                      />
                    </Bounce>
                    <div className="service-img cms rounded"></div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6">
                <h2 className="text-center text-md-left pt-5 pb-4">
                  CMS Add Ons
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
            <div className="pt-100 pb-5 text-center">
              <Link to="/" className="btn btn-primary">
                Get a Price
              </Link>
            </div>
          </div>
        </div>
        <div className="list-section">
          <svg
            className="wave"
            width="100%"
            height="217"
            viewBox="0 0 1440 217"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1440 81.5318L1380 76.5144C1320 72.1243 1200 63.3439 1080 67.7341C960 72.1243 840 90.3121 720 108.5C600 126.688 480 144.249 360 162.436C240 180.624 120 198.812 60 207.592L0 217V-4.17233e-06H60C120 -4.17233e-06 240 -4.17233e-06 360 -4.17233e-06C480 -4.17233e-06 600 -4.17233e-06 720 -4.17233e-06C840 -4.17233e-06 960 -4.17233e-06 1080 -4.17233e-06C1200 -4.17233e-06 1320 -4.17233e-06 1380 -4.17233e-06H1440V81.5318Z"
              fill="white"
            />
          </svg>
          <div className="container py-5">
            <div className="row justify-content-center align-items-baseline">
              <div className="col-md-6 col-lg-5">
                <Fade left>
                  <div className="orange-blob-bg">
                    <h6 className="text-uppercase text-white pb-2">
                      Our Expertise
                    </h6>
                    <h3 className="text-white">
                      We build websites that are designed with sales conversions
                      in mind. Our subscription model allows for amazing
                      long-term support, and no limits to the website growth
                      potential.
                    </h3>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6 col-lg-5">
                <Fade delay={1000}>
                  <div className="p-5 rounded-lg shadow-dark-lg bg-primary">
                    <ul className="big-checklist">
                      <Zoom right delay={1000}>
                        <li>
                          We are the ONLY provider of Sales Funnel Websites as a
                          service.
                        </li>
                        <li>
                          Our model of UNLIMITED pages, UNLIMITED changes, and
                          UNLIMITED design requests is absolutely unparalleled
                          across the board.
                        </li>
                        <li>
                          We&#39;re the best at conversions, and we offer
                          unbelievable support at an amazingly low monthly rate.
                        </li>
                      </Zoom>
                    </ul>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="pt-100 text-center">
              <Fade up>
                <h2 className="text-white">
                  <strong>Build a website that actually converts!</strong>
                  <br />
                </h2>
                <p className="lead py-4">
                  We're the best at conversions, and we offer unbelievable
                  support at an amazingly low monthly rate.
                </p>

                <Link className="btn btn-primary btn-orange shadow-dark mb-5">
                  Get a Price
                </Link>
              </Fade>
            </div>
          </div>
          <div className="gradient-bg"></div>
          <DotsCircleMultiple animationTrigger=".list-section" />
          <svg
            className="wave"
            width="100%"
            height="179"
            viewBox="0 0 1440 179"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1440 116.167H1380C1320 116.167 1200 116.167 1080 100.094C960 84.0204 840 52.6041 720 31.4163C600 10.9592 480 0 360 0C240 0 120 10.9592 60 16.0735L0 21.1878V179H60C120 179 240 179 360 179C480 179 600 179 720 179C840 179 960 179 1080 179C1200 179 1320 179 1380 179H1440V116.167Z"
              fill="#ecf0f3"
            />
          </svg>
        </div>
        <div className="faq-section bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h6 className="text-center text-uppercase text-orange pb-2">
                  Important to Know
                </h6>
                <h3 className="text-center pb-5">Frequently Asked Questions</h3>
                <LazyLoadComponent>
                  <FaqAccordion />
                </LazyLoadComponent>
              </div>
            </div>
          </div>
        </div>
        <div className="articles-section bg-white">
          <div className="container">
            <h6 className="text-center text-uppercase text-orange pb-2">
              WebriQ Blog
            </h6>
            <h2 className="text-center">Resource Library</h2>
            <div className="row justify-content-center py-5 article-row">
              <div className="col-md-6 col-lg-4 mb-4">
                <Link className="article-link h-100" to="/">
                  <div className="article-item rounded-lg h-100">
                    <div className="article-banner mb-3">
                      <i className="fa fa-file-text-o"></i>
                      <div
                        className="article-banner-img"
                        style={{
                          backgroundImage: `url(${require("@images/index/sample-video-thumb.jpg")})`,
                        }}
                      ></div>
                    </div>
                    <div className="article-text p-3 pb-4">
                      <span className="small text-white font-weight-bold text-uppercase d-inline-block px-2 bg-dark rounded mb-2">
                        August 11, 2020
                      </span>
                      <h4 className="mb-3">This is the post title.</h4>
                      <p className="pb-3">
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <Link className="article-link h-100" to="/">
                  <div className="article-item rounded-lg h-100">
                    <div className="article-banner mb-3">
                      <i className="fa fa-file-text-o"></i>
                      <div
                        className="article-banner-img"
                        style={{
                          backgroundImage: `url(${require("@images/index/austin-distel-h1RW-NFtUyc-unsplash.jpg")})`,
                        }}
                      ></div>
                    </div>
                    <div className="article-text p-3">
                      <span className="small text-white font-weight-bold text-uppercase d-inline-block px-2 bg-dark rounded mb-2">
                        August 25, 2020
                      </span>
                      <h4 className="mb-3">This is the post title.</h4>
                      <p className="mb-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <Link className="article-link h-100" to="/">
                  <div className="article-item rounded-lg h-100">
                    <div className="article-banner mb-3">
                      <i className="fa fa-file-text-o"></i>
                      <div
                        className="article-banner-img"
                        style={{
                          backgroundImage: `url(${require("@images/index/headway-5QgIuuBxKwM-unsplash.jpg")})`,
                        }}
                      ></div>
                    </div>
                    <div className="article-text p-3">
                      <span className="small text-white font-weight-bold text-uppercase d-inline-block px-2 bg-dark rounded mb-2">
                        September 8, 2020
                      </span>
                      <h4 className="mb-3">This is the post title.</h4>
                      <p className="mb-3">
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="text-center">
              <Link className="btn btn-primary" to="/">
                More Resources
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
