// @flow
import React from "react"
import styled from "styled-components"
import { Controller, Scene } from "react-scrollmagic"
import { Tween } from "react-gsap"

const TweenStyled = styled.div`
  .top-left {
    width: 20%;
    height: 20%;
    background: url(${require("@images/backgrounds/circle1.svg")}) no-repeat
      center;
    position: absolute;
    opacity: 0.25;
    z-index: 2;
  }
  .top-right {
    width: 20%;
    height: 25%;
    background: url(${require("@images/backgrounds/white-dots.svg")}) no-repeat
      center;
    position: absolute;
    opacity: 0.25;
  }
  .bottom-left {
    width: 30%;
    height: 25%;
    background: url(${require("@images/backgrounds/white-dots.svg")}) no-repeat
      center;
    position: absolute;
    opacity: 0.25;
  }
  .bottom-right {
    width: 25%;
    height: 25%;
    background: url(${require("@images/backgrounds/circle1.svg")}) no-repeat
      center;
    position: absolute;
    opacity: 0.25;
  }
`

const TweenComponent = ({ animationTrigger }) => (
  <TweenStyled>
    <Controller>
      <Scene triggerElement={animationTrigger} duration={1000} pin={false}>
        {progress => (
          <Tween
            from={{
              css: {
                top: "5vh",
              },
              ease: "Strong.easeOut",
            }}
            to={{
              css: {
                transform: "scale(2)",
              },
              ease: "Strong.easeOut",
            }}
            totalProgress={progress}
            paused
          >
            <div className="top-left" />
          </Tween>
        )}
      </Scene>
      <Scene triggerElement={animationTrigger} duration={1000} pin={false}>
        {progress => (
          <Tween
            from={{
              css: {
                top: "-25vh",
                right: "50px",
              },
              ease: "Strong.easeOut",
            }}
            to={{
              css: {
                top: "0",
              },
              ease: "Strong.easeOut",
            }}
            totalProgress={progress}
            paused
          >
            <div className="top-right" />
          </Tween>
        )}
      </Scene>
      <Scene triggerElement={animationTrigger} duration={10000} pin={false}>
        {progress => (
          <Tween
            from={{
              css: {
                bottom: "-200px",
                left: "-150px",
              },
              ease: "Strong.easeOut",
            }}
            to={{
              css: {
                bottom: "50vh",
              },
              ease: "Strong.easeOut",
            }}
            totalProgress={progress}
            paused
          >
            <div className="bottom-left" />
          </Tween>
        )}
      </Scene>
      <Scene triggerElement={animationTrigger} duration={10000} pin={false}>
        {progress => (
          <Tween
            from={{
              css: {
                bottom: "-10vh",
                right: "50px",
                transform: "scale(.5)",
              },
              ease: "Strong.easeOut",
            }}
            to={{
              css: {
                bottom: "10vh",
                transform: "scale(2)",
              },
              ease: "Strong.easeOut",
            }}
            totalProgress={progress}
            paused
          >
            <div className="bottom-right" />
          </Tween>
        )}
      </Scene>
    </Controller>
  </TweenStyled>
)

export default TweenComponent
