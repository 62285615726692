import React, { useState, useEffect } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

const params = {
	speed: 500,
	effect: "fade",
	slidesPerView: 1,
	mousewheel: {
		releaseOnEdges: true,
	},
	pagination: {
		el: ".swiper-pagination",
		type: "bullets",
		clickable: true,
	},
	keyboard: {
		enabled: true,
		onlyInViewport: false,
	},
}

const HomepageHero = () => {
	const [scroll, setScroll] = useState(false)

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 100)
		})
	}, [])

	return (
		<>
			<div className={`homepage-hero ${scroll ? "scrolled" : ""} `}>
				<div className="index-hero-swiper">
					<Swiper {...params}>
						<div className="hero-slide">
							<div className="container">
								<div className="swiper-text-wrapper">
									<div className="row justify-content-center">
										<div className="col-lg-8">
											<h1 className="display-2">
												Sales Websites
												<br />
												<span className="accented-text">
													As A Service
													<br />
												</span>
											</h1>
											<h4 className="py-4 font-weight-normal">
												The answer to business websites that don't convert.
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="hero-slide">
							<div className="container">
								<div className="swiper-text-wrapper">
									<div className="row justify-content-center">
										<div className="col-lg-8">
											<h2 className="display-3">
												<span className="accented-text">Unlimited</span> Pages
											</h2>
											<h4 className="py-4 font-weight-bold">
												For Your Marketing Needs
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="hero-slide">
							<div className="container">
								<div className="swiper-text-wrapper">
									<div className="row justify-content-center">
										<div className="col-lg-8">
											<h2 className="display-3">
												<span className="accented-text">Unlimited</span> Funnels
											</h2>
											<h4 className="py-4 font-weight-bold">
												Sales Funnels That Actually Convert!
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="hero-slide">
							<div className="container">
								<div className="swiper-text-wrapper">
									<div className="row justify-content-center">
										<div className="col-lg-8">
											<h2 className="display-3">
												<span className="accented-text">Unlimited</span> Updates
											</h2>
											<h4 className="py-4 font-weight-bold">
												No-limits Web Development
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>spacer slide*/}</div>
					</Swiper>
				</div>

				<div className="animated-scroll">
					<AnchorLink href="#thesis" offset="125">
						<span></span>
					</AnchorLink>
				</div>
			</div>
			<div className="smooth-gradient-filler" />
		</>
	)
}

export default HomepageHero
